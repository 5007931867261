import React from 'react';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'"Magnetic Poetry Kit" is the registered trademark of Magnetic Poetry, Inc. Copyright © 2007 - '}
      
      {new Date().getFullYear()}
      {', Magnetic Poetry, Inc. All rights reserved.'}
      {` `}
      <MuiLink color="inherit" href="https://magneticpoetry.com/">
        Magnetic Poetry
      </MuiLink>{' '}
    </Typography>
  );
}