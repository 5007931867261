import * as React from "react"
import Container from '@material-ui/core/Container'
import Copyright from '../components/Copyright'
import PropTypes from 'prop-types'

const Footer = ({ siteTitle, menuLinks, activeSlug }) => (
    <footer
        style={{
            marginTop: `4rem`,
            padding: `1em 0`,
            borderTop: `2px solid ${menuLinks.find(slug => slug.name === activeSlug).color}`
        }}
    >
        <Container>
            <Copyright/>
        </Container>
    </footer>
)

Footer.propTypes = {
    activeSlug:PropTypes.string,
    menuLinks:PropTypes.array,
  }
  
Footer.defaultProps = {
    activeSlug:`original`,
    menuLinks: [{name: 'home',link:'/'}],
  }

export default Footer
