import React, { useState, useRef } from "react";

import { Grid, Paper, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import ClearIcon from '@material-ui/icons/Clear'
import domtoimage from 'dom-to-image'
import { saveAs } from "file-saver"

import Magnet from "./magnet.js"
import Buy from "./buy.js"

// styling
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "50vh",
    gridTemplateRows: "1fr min-content",
  },
  fridge: {
    height: "100%",
    minHeight: "50vh",
    backgroundColor: "#E7F0F4",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "stretch",
    textAlign: "center"

  },
  dropArea: {
    width: "100%",
    height: "100%",
  },
  
  invisible: {
    opacity: 0,
    cursor: "off",
  }
}));

// helper functions

function LoadedOnly({ children, ...delegated }) {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
      setHasMounted(true);
  }, []);
  if (!hasMounted) {
      return null;
  }
  return (
      <div {...delegated}>
          {children}
      </div>
  );
}

function randomNum(min, max){
	return min + Math.floor(Math.random() * (max-min + 1));
}

function shuffle (array) {
  let currentIndex = array.length;
  while (currentIndex !== 0) {
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    [ array[currentIndex], array[randomIndex] ] = [ array[randomIndex], array[currentIndex] ];
  }
  return array;
}

const saveImage = (node) => domtoimage.toPng(node)
.then(function (blob) {
    window.saveAs(blob, 'my-poem.png');
})


/* set up game
================ */
let wordsData = []
let allWords = []
let currentSlug = ''
let currentSetIndex = 0
const maxWordsInSet = 40
let wordsOnFridge = []
let wordsOnTray = []
let totalSetsNumber = 1

function initiateData(data) {
  wordsData = data.trim().split(",\n")
  const shuffledWords = shuffle(wordsData)
  allWords = shuffledWords.map((word, index) => ({
    'id' : `${word}-${index.toString()}`, 
    'string' : word,
    'posX' : 0, 
    'posY' : 0, 
    'rotation' : randomNum(-4, 4),
    'active' : false,
  }))
  totalSetsNumber = Math.floor(allWords.length/maxWordsInSet)
}

const GameUi = ({ data, kit }) => {

  // styling
  const classes = useStyles()

  const poemAreaReference = useRef(null)

  // ========== STATES =============

  const [activeWords, updateArray] = useState([wordsOnFridge, wordsOnTray])

  // get new word data
  const resetSet = () => {
      // getting new data
      currentSlug = kit.name
      initiateData(data)
      wordsOnFridge = []
      wordsOnTray = allWords.slice(0, maxWordsInSet)
      updateArray([wordsOnTray, wordsOnFridge])
  }

  if (allWords.length === 0 || kit.name !== currentSlug){
    currentSlug = kit.name
    resetSet()
    updateArray([wordsOnTray, wordsOnFridge])
  }
  // function for loading new words in existing set
  const updateSet = () => {
    currentSetIndex + 1 < totalSetsNumber ? currentSetIndex++ : currentSetIndex = 0
    let startIndex = currentSetIndex * maxWordsInSet
    let endIndex = (currentSetIndex + 1) * maxWordsInSet
    wordsOnTray = allWords.slice(startIndex, endIndex)
    updateArray([wordsOnTray, wordsOnFridge])
  }
  

  /* DROP FUNCTION
  ===================================================
  what happens when we drag and drop drop the magnet

  cases: 
  - dropped on fridge first time
  - changed position, but stayed on fridge
  - dropped outside of fridge
  */

  const preventDefaultDrag = (event) => {
    event.preventDefault();
  }

  const setDataUp = (event) => {
    /* the onDrop handler works from the drop target div and not from the node dropped
    and firefox's drag API doesn't work for node positions (clientX, clientY)
    => the work-around is to pass data through the node via data-* and pass it via the word node being dropped */

    // get the word object from the rendered node
    const word = {
      'id' : event.target.getAttribute('data-id'), 
      'string' : event.target.getAttribute('data-string'), 
      'posX' : event.target.getAttribute('data-xpos') || 0, 
      'posY' : event.target.getAttribute('data-ypos') || 0, 
      'rotation' : event.target.getAttribute('data-rotation'),
      'setIndex' : event.target.getAttribute('data-setindex'),
      'active' : event.target.parentNode.classList.contains("drop-area"),
    }
    // covert object to JSON string for data transfer
    const wordString = JSON.stringify(word)
    // set data for transfer: word JSON 
    event.dataTransfer.setData( "word", wordString )
    // set data for transfer: index
    event.dataTransfer.setData("index", event.target.getAttribute('data-index'))
    // also get offset values from the rendered div's width and height
    event.dataTransfer.setData("width", event.target.clientWidth)
    event.dataTransfer.setData("height", event.target.clientHeight)
  }

  const handleDrop = (event) => {
    // the onDrop handler can now access the word data 
    // convert it back to an object
    const word = JSON.parse(event.dataTransfer.getData("word"))
    // we also need our index so we can remove the word from the original tray array
    const wordIndex = event.dataTransfer.getData("index")
    const offsetX = event.dataTransfer.getData("width") / 2
    const offsetY = event.dataTransfer.getData("height") / 2
    const fridge = poemAreaReference.current
    const fridgeX = fridge.getBoundingClientRect().left
    const fridgeY = fridge.getBoundingClientRect().top

    word.posX = event.clientX - fridgeX - offsetX
    word.posY = event.clientY - fridgeY - offsetY

    if(!word.active) {
      // console.log('pushing word')
      word.active = true
      wordsOnFridge.push(word)
      // console.log('removing from tray')
      wordsOnTray[wordIndex] = word
      // remove from complete set
      const indexInAllWords = allWords.findIndex((element) => element.id == word.id)
      allWords.splice(indexInAllWords, 1)
      // render update
      updateArray([wordsOnTray, wordsOnFridge ])
    } else {
      // if the word is already on the fridge, we still need to update this, to update position
      // find the dropped word in the array of words on fridge
      const indexOnFridge = wordsOnFridge.findIndex((element) => element.id == word.id)
      // and remove from fridge array
      wordsOnFridge.splice(indexOnFridge, 1)
      // and put back to fridge array with new x and y values
      wordsOnFridge.push(word)
      // render update
      updateArray([wordsOnTray, wordsOnFridge ])
    }
  }
  return(
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.root}>
          <div style={{backgroundColor: "#393232", padding: "1rem", borderRadius: "0.5rem"}}>
            <div style={{textAlign: "center",}} >
          <Button
              onClick={() => updateSet()}
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              style={{margin: "1em"}}
              startIcon={<RefreshIcon />}
            >
              Load more words
            </Button>
            </div>
            <LoadedOnly>
            {activeWords[0].map((word, index) => (
              // Todo, if this compontent has been dragged already, keep the rotation etc
              <div 
              draggable={!word.active}
              onDragStart = {(event) => setDataUp(event)}
              key = {word.id}
              data-xpos = {word.xpos}
              data-ypos = {word.ypos}
              data-rotation = {word.rotation}
              data-string = {word.string}
              data-index = {index}
              data-id = {word.id}
              data-active = {word.active}
              className = {word.active? classes.invisible : "visible"}
              style = {{transform: `rotate(${word.rotation}deg)`, display: "inline-block"}}
              >
                <Magnet>{word.string}</Magnet>
                
              </div>
              
            ))}
            </LoadedOnly>
            </div>
            <Buy kit={kit} />
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9}>
          
          <Paper variant="outlined" className={`${classes.fridge}`}>
            <div 
            ref={poemAreaReference}
            className={`drop-area ${classes.dropArea}`} 
            style={{position: "relative"}}
            onDragEnter = {(event) => preventDefaultDrag(event)}
            onDragOver= {(event) => preventDefaultDrag(event)}
            // onDrop = {(event) => handleDrop(event, word, index, event.target.parentNode)}
            onDrop = {(event) => handleDrop(event)}
            >

              
            {activeWords[1].map((word, index) => (
              <div 
              draggable="true"
              onDragStart = {(event) => setDataUp(event)}
              key = {word.id}
              data-xpos = {word.xpos}
              data-ypos = {word.ypos}
              data-rotation = {word.rotation}
              data-string = {word.string}
              data-id = {word.id}
              data-index = {index}
              data-active = {word.active}
              style = {{display: "inline-block", color: "red", transform: `rotate(${word.rotation}deg)`, position: "absolute", left: `${word.posX}px`, top: `${word.posY}px` }}
              >
                <Magnet>{word.string}</Magnet>
                
              </div>
            ))}
            </div>
            <div>
            <Button
            onClick={() => saveImage(poemAreaReference.current)}
            variant="contained"
            color="secondary"
            size="small"
            className={classes.button}
            startIcon={<SaveAltIcon />}
            style={{margin: "1em"}}
          >Save Poem</Button>
          <Button
            onClick={() => resetSet()}
            variant="contained"
            color="secondary"
            size="small"
            className={classes.button}
            startIcon={<ClearIcon />}
            style={{margin: "1em"}}
          >
            Reset
          </Button>
              </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default GameUi
