import React from 'react'
import PropTypes from "prop-types"
import { Link } from '@material-ui/core'
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./logo.module.css"


const Logo = ({siteTitle, siteDescription}) => (
<Link to="/">
    <div className={styles.site_logo}>
    <StaticImage
        src="../images/logo.svg"
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF", "SVG"]}
        alt= {`${siteTitle} - ${siteDescription}`}
    /></div>
</Link>
)

Logo.propTypes = {
    siteTitle: PropTypes.string,
    siteDescription: PropTypes.string,
}
  
Logo.defaultProps = {
    siteTitle: ``,
    siteDescription: ``,
}

export default Logo