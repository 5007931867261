import React from "react"
import { useStaticQuery, graphql } from "gatsby"


const Buy = ({ kit }) => {
    return (
        <a href={kit.shop} target="_blank" style={{ 
            backgroundColor: `${kit.color}`, 
            padding: "0.5rem 1rem",
            borderRadius: "0.5rem",
            margin: "1.5rem 0 0",
            fontWeight: "600",
            textTransform: "uppercase",
            fontSize: "1.125rem",
            color: "white",
            display: "block",
            textDecoration: "none",
            }}>
            <span style={{fontWeight: "400", display: "block"}}>
                {kit.name} kit
            </span>
            <span>
                Buy and play at home
            </span>
        </a>
    )
  }

  export default Buy