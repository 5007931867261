import React from 'react'

const MagnetStyles = {
    backgroundColor: "white", 
    padding: "0.25em 0.5em",
    margin: "0.5em",
    color: "#000", 
    borderRadius: 0, 
    border: "1px solid black",
    borderBottomWidth: "3px",
    fontFamily: "Merriweather, serif",
    fontSize: "0.875rem",
    fontWeight: "400",
    display: "inline-block",
    cursor: "pointer",
}

const Magnet = ({ children, word}) => { return (
        <span style={MagnetStyles}>
            {children}
        </span>
)}

export default Magnet;