import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Container from '@material-ui/core/Container'
import Header from "./header"
import Footer from "./footer"
import GameUi from "./gameui"


const Layout = ({ children, slug }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          menuLinks {
            name
            link
            color
            shop
          }
        }
      }
      allPlainText {
        nodes {
          content
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  `)
    const slugData = data.allPlainText.nodes.find(node => (
      node.parent.name === slug
    ))
    const kitDetails = data.site.siteMetadata.menuLinks.find(kit => (
      kit.name === slug
    ))
  return (
    <>
      <Header 
        siteTitle={data.site.siteMetadata.title}
        siteDescription={data.site.siteMetadata.description}
        menuLinks={data.site.siteMetadata.menuLinks}
        activeSlug={slug}
      />
      <Container>
      <h1 style={{color: "#524848", fontSize: "3em", textTransform: "uppercase", display: "flex"}}><span style={{fontSize: "0.5em", marginTop: "0.4em", marginRight: "0.5em"}}>Play with the</span> {slug} kit</h1>
      
      <GameUi data={slugData.content} kit={kitDetails}/>
      </Container>
      <Footer
      siteTitle={data.site.siteMetadata.title}
      menuLinks={data.site.siteMetadata.menuLinks}
      activeSlug={slug}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
