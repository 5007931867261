import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Button, Container } from '@material-ui/core'
import {Link} from 'gatsby-theme-material-ui'
import Logo from './logo.js'
import background from "./bg.png"


const Header = ({ siteTitle, siteDescription, menuLinks, activeSlug }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
      padding: "2em 0",
      borderBottom: `5px solid ${menuLinks.find(slug => slug.name === activeSlug).color}`,
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
    }}
  >
    <Container>
    <div style={{display: "flex", flexWrap: "wrap"}}>
          <Logo siteTitle={siteTitle} siteDescription={siteDescription} />
      
      <div>

            {menuLinks.map(link => (
                <Button 
                key={link.name}
                variant={activeSlug === link.name ? "outlined" : "contained"} 
                style={activeSlug === link.name ? {margin: "0.5em", backgroundColor: "#393232", borderColor: link.color, color: "#fff"} : {margin: "0.5em", backgroundColor: link.color, color: "#fff"}}
                >
                  <Link to={link.link} color="inherit" state={{slug: link.name}}>{link.name}</Link>
                </Button>
              
              ))}
          
          </div>
        
          </div>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  menuLinks:PropTypes.array,
  activeSlug:PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
  menuLinks: [{name: 'home',link:'/'}],
  activeSlug:``,
}

export default Header
